<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="PerCatId"
        item-text="PerCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        :disabled="disabled"
        return-object
        ref="PerCatFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'PerId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: [
      'value', 'perCatIdParent', 'perCatIdAnyLevel', 'perCatToHide', 'tipoFiltro', 
      'showParentsOnly', 'showNoParentOnly','label', 'multiple', 'disabled', 
      'readonly', 'defValue', 'smallChips', 'deletableChips', 'perIdRegistrado',
      'selectOnReadonly'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      perCatIdParent() {
        this.$refs.PerCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
      perCatIdAnyLevel() {
        this.$refs.PerCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            PerCatNom: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            PerCatIdParent: this.perCatIdParent,
            PerCatIdAnyLevel: this.perCatIdAnyLevel,
            TipoFiltro: this.tipoFiltro,
            PerIdRegistrado: this.perIdRegistrado,
            ShowParentsOnly: this.showParentsOnly,
            ShowNoParentOnly: this.showNoParentOnly,
            Searcher: true
        };

        var defVal = this.defValue; // si no se asigna a variable y se asigna con this no lo coge 
        var curVal = this.value;

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/PerCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
            this.itemsList = [];

            const idsToHide = this.perCatToHide != null ? this.perCatToHide.map(m => m.PerCatId) : [];
            result.data.EntsList.forEach(element => {
              if(idsToHide.indexOf(element.PerCatId) == -1) {
                var itemObject = {PerCatId: element.PerCatId, PerCatNom: element.PerCatNom}
                this.itemsList.push(itemObject);
              }
            });

            if(curVal != null) {
              // if (curVal.length == null) {
              if (!Array.isArray(curVal)) {  // es un objeto
                if (curVal.PerCatId != null) {
                  if (!this.itemsList.some(itm=>itm.PerCatId === curVal.PerCatId)) {
                    this.itemsList.push(curVal);
                  }
                }
                else {
                  if (defVal != null && this.itemsList != null && this.itemsList.length > 0) {
                    var itmDefault = this.itemsList.find(itm=>itm.PerCatId === Number(defVal))
                    if (!this.itemsList.some(itm=>itm.PerCatId === Number(defVal))) {
                      this.itemsList.push(itmDefault);
                    }
                    this.value = itmDefault;
                    this.$emit('input', this.value);
                  }
                }
              }
              else {
                curVal.forEach(element => {  // es un array
                    if (!this.itemsList.some(itm=>itm.PerCatId === element.PerCatId)) {
                      this.itemsList.push(element);
                    }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.PerCatFindCtrl.isMenuActive) {
            this.$refs.PerCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.PerCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.PerCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems('');
    }
  }
</script>
