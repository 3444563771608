<template>
    <div>
        <v-container>
            <v-card style="background-color: #f5f5f5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    permiso de mensajería
                </div>

                <v-form
                    ref="form"
                    v-model="isValid"
                    autocomplete="off"
                >
                    <v-tabs v-model="tab" background-color="transparent" show-arrows>
                        <v-tabs-slider color="blue"></v-tabs-slider>
                        <v-tab href="#tab-pmsmsj">Permiso</v-tab>
                        <v-tab href="#tab-percat">Categorías permitidas</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" touchless>
                        <!-- Formulario del permiso -->
                        <v-tab-item :value="'tab-pmsmsj'">
                            <v-card style="background-color: #f5f5f5" flat>
                                <v-row>
                                    <v-col class="px-7 mt-4" cols="12" sm="9" md="6" lg="6" xl="5">
                                        <v-text-field
                                            outlined
                                            dense
                                            text
                                            v-model="pmsMsj.PmsMsjNom"
                                            required
                                            :rules="[v => !!v || 'El nombre es obligatorio']"
                                            label="Nombre *"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                        <!-- Tabla de categorías -->
                        <v-tab-item :value="'tab-percat'">
                            <v-card style="background-color: #f5f5f5" flat>
                                <v-row class="mt-0">
                                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Boton añadir categoría -->
                                        <v-btn
                                        :loading="loading"
                                        :disabled="editNewPerCat"
                                        @click="onBtnNuevaPerCat"
                                        color="primary"
                                        small
                                        class="mb-2">
                                            Añadir categoría
                                        </v-btn>

                                        <v-data-table
                                            :headers="perCatHeaders"
                                            :items="perCatDataRows"
                                            :options.sync="perCatOptions"
                                            :footer-props="{itemsPerPageOptions: [100]}"
                                            :server-items-length="perCatTotalDataRows"
                                            :loading="perCatLoading"
                                            @click:row="onClickRowEditarPerCat"
                                            style="overflow: scroll"
                                            mobile-breakpoint="0"
                                            class="elevation-1"
                                            dense
                                        >
                                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                                            <template v-slot:item.PerCat.PerCatNom="{ item }">
                                                <span v-if="!item.edit">{{item.PerCat.PerCatNom}}</span>
                                                <div v-else class="px-0 py-2" style="min-width: 90px">
                                                    <PerCatFind class="mb-n7" ref="perCatEditCtrl" v-model="perCatEditData.PerCat" label="Categoría"></PerCatFind>
                                                </div>
                                            </template>
                                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                                            <template v-slot:item.LimDir="{ item }">
                                                <v-simple-checkbox v-if="!item.edit" v-model="item.LimDir" disabled v-ripple></v-simple-checkbox>
                                                <v-simple-checkbox v-else v-model="perCatEditData.LimDir" color="primary" v-ripple></v-simple-checkbox>
                                            </template>
                                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                                            <template v-slot:item.PmsInd="{ item }">
                                                <v-simple-checkbox v-if="!item.edit" v-model="item.PmsInd" disabled v-ripple></v-simple-checkbox>
                                                <v-simple-checkbox v-else v-model="perCatEditData.PmsInd" color="primary" v-ripple></v-simple-checkbox>
                                            </template>
                                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                                            <template v-slot:item.PmsGrp="{ item }">
                                                <v-simple-checkbox v-if="!item.edit" v-model="item.PmsGrp" disabled v-ripple></v-simple-checkbox>
                                                <v-simple-checkbox v-else v-model="perCatEditData.PmsGrp" color="primary" v-ripple></v-simple-checkbox>
                                            </template>
                                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                                            <template v-slot:item.Opt="{ item }">
                                                <v-btn v-if="!item.edit"
                                                color="error"
                                                class="mr-2 my-1"
                                                small
                                                @click.stop="onBtnShowPerCatDeleteModal(item)">
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                                <div v-else class="px-0 py-1" style="min-width: 200px" align ="right">
                                                    <v-btn
                                                    :loading="perCatLoading"
                                                    :disabled="!isValidPerCat(perCatEditData, false)"
                                                    @click.stop="onBtnEditPerCat(item)"
                                                    color="primary"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                        Aceptar
                                                    </v-btn>
                                                        
                                                    <v-btn
                                                    @click.stop="cancelPerCatEdit"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                        Cancelar
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <!-- NUEVA CATEGORIA FORMULARIO -->
                                            <template v-if="editNewPerCat" slot="body.append">
                                                <tr class="new-row">
                                                    <td>
                                                        <PerCatFind class="mb-n7" ref="perCatNewCtrl" v-model="newPerCat.PerCat" label="Categoría"></PerCatFind>
                                                    </td>
                                                    <td align="center">
                                                        <v-simple-checkbox v-model="newPerCat.LimDir" color="primary" v-ripple></v-simple-checkbox>
                                                    </td>
                                                    <td align="center">
                                                        <v-simple-checkbox v-model="newPerCat.PmsInd" color="primary" v-ripple></v-simple-checkbox>
                                                    </td>
                                                    <td align="center">
                                                        <v-simple-checkbox v-model="newPerCat.PmsGrp" color="primary" v-ripple></v-simple-checkbox>
                                                    </td>
                                                    <td align="right">
                                                        <div class="px-0 py-1" style="min-width: 200px" align ="right">
                                                            <v-btn
                                                            :loading="perCatLoading"
                                                            :disabled="!isValidPerCat(newPerCat)"
                                                            @click="onBtnSavePerCat"
                                                            color="primary"
                                                            class="mt-2 mb-4 mr-2"
                                                            small>
                                                                Aceptar
                                                            </v-btn>
                                                                
                                                            <v-btn
                                                            @click="onBtnCancelPerCat"
                                                            class="mt-2 mb-4 mr-2"
                                                            small>
                                                                Cancelar
                                                            </v-btn>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    
                    <!-- ACEPTAR / CANCELAR / BORRAR -->
                    <v-row class="mt-2" justify="center">
                        <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                            Aceptar
                        </v-btn>
                        
                        <v-btn
                        to="/permisos-mensajeria"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                            Cancelar
                        </v-btn>

                        <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal">
                            Borrar
                        </v-btn>
                    </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Permiso -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR PERMISO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el permiso? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn
                    @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar contacto -->
        <v-dialog
          v-model="showPerCatDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR CATEGORIA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la categoría?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perCatLoading"
                color="primary"
                @click="onBtnPerCatDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPerCatDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import PerCatFind from "../components/PerCatFind.vue";
export default {
    components: {
        PerCatFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showPerCatDeleteDialog: false,
            pmsMsj: {
                PmsMsjId: 0,
                PmsMsjNom: ''
            },
            // Datatable categorias persona
            perCatLoading: false,
            perCatTotalDataRows: 0,
            perCatDataRows: [],
            perCatOptions: {itemsPerPage: 100},
            perCatHeaders: [
                { text: 'Categoría', width:240, sortable: false, value: 'PerCat.PerCatNom' },
                { text: 'Limitar direcciones', width:136, sortable: false, value: 'LimDir', align: 'center' },
                { text: 'Permiso individual', width:133, sortable: false, value: 'PmsInd', align: 'center' },
                { text: 'Permiso grupos', width:118, sortable: false, value: 'PmsGrp', align: 'center' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición categorias persona
            editNewPerCat: false, // Indica si se esta editando la nueva categoria
            newPerCat: {}, // Datos de la nueva categoria
            perCatEditData: null, // Datos de la categoria a editar
            selectedPerCat: null, // Categoria seleccionada para borrar
        }
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.pmsMsj.PmsMsjId = this.$route.params.id;
        this.loadData();
      }
    },
    methods: {
        goToList() {
            this.$router.push('/permisos-mensajeria');
        },

        //#region Llamadas a la API
        getDataFromApi() {
            this.loading = true;
            return new Promise((resolve) => {
                const controllerParameters = {
                    Action: 'GET_PMSMSJ',
                    PmsMsjId: this.pmsMsj.PmsMsjId,
                    EmpId: this.empId
                };       
                const AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/pmsmsj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const item = result.data.PmsMsj;

                    setTimeout(() => {
                        this.loading = false;
                        resolve({
                            item
                        })
                    }, 1000)
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert("Error al cargar el permiso");
                        this.goToList();
                    }
                });
            });
        },
        postDataToApi() {
            this.loading = true;

            // Asignamos los IDs de los objetos hijos
            const pmsMsjPerCats = this.perCatDataRows.map(m => { 
                return { 
                    PerCatId: m.PerCat.PerCatId, 
                    LimDir: m.LimDir, 
                    PmsInd: m.PmsInd,
                    PmsGrp : m.PmsGrp
                }; 
            });

            return new Promise((resolve) => {
                const controllerParameters = {
                    Action: 'SAVE_ENTITY',
                    // EmpId: this.empId,
                    PmsMsj: this.pmsMsj,
                    PmsMsjPerCats: pmsMsjPerCats
                };
                const AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/pmsmsj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const item = result.data;

                    setTimeout(() => {
                        this.loading = false;
                        resolve({
                            item
                        })
                    }, 1000)
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert("Error al guardar el permiso.");
                    }
                });
            });
        },
        deleteDataFromApi(){
            this.loading = true;
            return new Promise((resolve) => {
                const controllerParameters = {
                    Action: 'DEL_ENTITY',
                    PmsMsjId: this.pmsMsj.PmsMsjId
                };       
                const AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": this.urlRaiz + "/api/pmsmsj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result => {
                    const item = result.data;

                    setTimeout(() => {
                        this.loading = false;
                        resolve({
                            item
                        })
                    }, 1000)
                })
                .catch(error => {
                    if(error != null) {
                        this.loading = false;
                        alert("Error al borrar el permiso");
                    }
                });
            });
        },
        //#endregion

        //#region Carga de datos
        loadData() {
            this.getDataFromApi()
            .then(data => {
                if(data.item == null) {
                    alert("Permiso no encontrado");
                    this.goToList();
                    return;  
                }

                this.pmsMsj = data.item;

                // Cargamos los datos de la tabla de categorías
                this.perCatTotalDataRows = data.item.PmsMsjPerCat.length;
                this.perCatDataRows = data.item.PmsMsjPerCat;
            });
        },
        //#endregion

        //#region  Añadir categorías
        onBtnNuevaPerCat() {
            this.editNewPerCat = true;
            this.newPerCat = { PmsMsjPerCatId: 0, edit: false };
        },
        //#endregion

        //#region Click guardado
        onBtnSave() {
            this.validate();
            if(!this.isValid) { return; }
            this.postDataToApi().then(data => {
                if(data.item.PmsMsjId != null) {
                    this.goToList();
                }
                else {
                    alert("Error al guardar el permiso.");
                }
            });
        },
        onBtnSavePerCat() {
            if(!this.isValidPerCat(this.newPerCat)) { return; }
            this.perCatDataRows.push(this.newPerCat);
            this.perCatTotalDataRows += 1;
            this.newPerCat = {};
            this.editNewPerCat = false;
        },
        //#endregion

        //#region Editar categorias
        cancelPerCatEdit(){
            const editPerCats = this.perCatDataRows.filter(f => f.edit);
            editPerCats.forEach(perCat => {
                perCat.edit = false;
            });
            
            this.perCatEditData = null;
            this.$forceUpdate();
        },
        onClickRowEditarPerCat(item){
            if(this.loading) { return; }
            if(item.edit) { return; }
            
            this.cancelPerCatEdit();
            this.perCatEditData = {
                PerCat: item.PerCat,
                PerCatId: item.PerCatId,
                LimDir: item.LimDir,
                PmsInd: item.PmsInd,
                PmsGrp : item.PmsGrp
            };

            item.edit = true;
            this.$forceUpdate();
        },
        onBtnEditPerCat(dtData){
            if(!this.isValidPerCat(this.perCatEditData, false)) { return; }
            if(dtData != null) {
                dtData.PerCat = this.perCatEditData.PerCat;
                dtData.PerCatId = this.perCatEditData.PerCat.PerCatId;
                dtData.LimDir = this.perCatEditData.LimDir;
                dtData.PmsInd = this.perCatEditData.PmsInd;
                dtData.PmsGrp = this.perCatEditData.PmsGrp;

                this.cancelPerCatEdit();
            }
        },
        //#endregion


        //#region Click borrar
        onBtnDelete(){
            this.deleteDataFromApi().then(data => {
                if (data.item.Success) {
                    this.showDeleteDialog = false;
                    this.goToList();
                }
                else {
                    alert(data.item.Message);
                }
            });
        },
        //#endregion

        //#region  Cancelar creación de categoría
        onBtnCancelPerCat() {
            this.editNewPerCat = false;
            this.newPerCat = {};
        },
        //#endregion

        //#region Eliminar categorías
        removePerCat(item){
            var index = this.perCatDataRows.indexOf(item);
            if (index != -1){
                this.perCatDataRows.splice(index, 1);
                this.perCatTotalDataRows -= 1;
            }
        },
        onBtnPerCatDelete(){
            this.removePerCat(this.selectedPerCat);
            this.selectedPerCat = null;
            this.showPerCatDeleteDialog = false;
        },
        //#endregion

        //#region Gestión de modales
        onBtnShowDeleteModal(){
            this.showDeleteDialog = true;
        },
        onBtnShowPerCatDeleteModal(item){
            this.selectedPerCat = item;
            this.showPerCatDeleteDialog = true;
        },
        onBtnCancelDelete() {
            this.showDeleteDialog = false;
        },
        onBtnCancelPerCatDelete() {
            this.selectedPerCat = null;
            this.showPerCatDeleteDialog = false;
        },
        //#endregion

        //#region Validar formulario
        validate() {
            this.$refs.form.validate();
        },
        isValidPerCat(pmsMsjPerCat, isNew = true) {
            if(pmsMsjPerCat == null) { return false; }
            if(pmsMsjPerCat.PerCat == null) { return false; }
            
            // Si no es una nueva categoría solo comprobamos los registro que no se esten editando
            const equalPerCats = this.perCatDataRows.filter(f => f.PerCat.PerCatId == pmsMsjPerCat.PerCat.PerCatId && (isNew || (!isNew && !f.edit)));
            return equalPerCats.length == 0;
        },
        //#endregion
    }
}
</script>
